<template>
  <!-- inicio -->
  <section v-if="type == null">
    <div class="text-center minH mt-5 pt-5">Buscando...</div>
  </section>

  <section class="minH mt-5 pt-5" v-if="type == 'not-items'">
    <div class="text-center"> Nenhum item encontrado...</div>
  </section>
  <!-- ordem -->
  <section  v-if="type == 'ordem'">
    <div >
      <section>
        <div>
          <div class="row">
            <div class="col-lg-2 text-left">
              <img
                v-if="isMobile == false"
                height="100"
                max-width="30"
                src="../../public/logo/hecc.jpeg"
              />
            </div>
            <div class="col-lg-8 col-12 text-center">
              <h5>Ordem de Serviço - {{ dados.ORDS_ID }}</h5>

              <div v-if="dados.EMP_DADOS">
                <div class="text-sub">
                  {{ dados.EMP_DADOS.EMP_DESCRICAO }}
                </div>
                <div class="outer-text">
                  <a
                    >{{ dados.EMP_DADOS.EMP_CGC }} -
                    {{ dados.EMP_DADOS.EMP_IE }}</a
                  ><br />
                  <a>
                    {{ dados.EMP_DADOS.EMP_ENDERECO }}
                    {{ dados.EMP_DADOS.EMP_CID_ID }}/{{
                      dados.EMP_DADOS.EMP_UF
                    }}
                    - {{ dados.EMP_DADOS.EMP_CEP }}</a
                  ><br />
                  <a>{{ dados.EMP_DADOS.EMP_FONE }}</a>
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <img
                v-if="isMobile == false"
                src="http://www.bssoft.com.br/assets/img/logo.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row  sub-header-order">
          <div class="col-lg-8">Dados do Cliente</div>
          <div class="col-lg-4 text-right">{{ dados.ORDI_STATUS }}</div>
        </div>
        <div class="order-item row text-sub">
          <a class="col-lg-4 col-sm-12">Cliente: {{ dados.ORDS_CLI_ID }} {{ dados.ORDS_CLI_DESC }}</a
          >
          <a class="col-lg-4 col-12 col-sm-12">CPF/CNPJ: {{ dados.ORDS_CLI_CNPJ_CPF }}</a>
          <a class="col-lg-4 col-12 col-sm-12">RG/IE: {{ dados.ORDS_CLI_IE_RG }}</a>
          <a class="col-lg-4 col-9 col-sm-9">End: {{ dados.ORDS_CLI_END }}</a>
          <a class="col-lg-4 col-3 col-sm-3">Nº: {{ dados.ORDS_CLI_NUMERO }}</a>
          <a class="col-lg-4 col-6 col-sm-6">Bairro: {{ dados.ORDS_CLI_BAIRRO }}</a>
          <a class="col-lg-4 col-6 col-sm-6">Comp: </a>
          <a class="col-lg-4 col-6">Fone: {{ dados.ORDS_CLI_FONE }}</a>
          <a class="col-lg-4 col-6">Cel: {{ dados.ORDS_CLI_CELULAR }}</a>
          <a class="col-lg-4 col-6">Cidade: {{ dados.ORDS_CLI_CIDADE }}</a>
          <a class="col-lg-4 col-6">UF: {{ dados.ORDS_CLI_UF }}</a>
          <a class="col-lg-4 col-12">E-mail: {{ dados.ORDS_CLI_EMAIL }}</a>
        </div>
        <div
          v-if="dados.ORDS_EQPT_SERIE"
          class="row  sub-header-order"
        >
          <div class="col-lg-12">Dados do Equipamento</div>
        </div>

        <div
          v-if="dados.ORDS_EQPT_SERIE"
          class="order-item  row text-sub"
        >
          <a class="col-lg-4 col-6">Série: {{ dados.ORDS_EQPT_SERIE }}</a>
          <a class="col-lg-4 col-6">Marca: {{ dados.ORDS_MREQ_DESCRICAO }}</a>
          <a class="col-lg-4 col-6">Modelo: {{ dados.ORDS_MDEQ_DESCRICAO }} </a>
          <a class="col-lg-4 col-6">Comp: {{ dados.ORDS_EQPT_COMPLEMENTO }}</a>
        </div>
        <div
          v-if="dados.ORDS_VEIC_PLACA1"
          class="row  sub-header-order"
        >
          <div class="col-lg-8">Dados do Veículo</div>
        </div>
        <div
          v-if="dados.ORDS_VEIC_PLACA1"
          class="order-item  row text-sub"
        >
          <a class="col-lg-4 col-6">Marca: {{ dados.ORDS_MRCV_DESCRICAO }}</a>
          <a class="col-lg-4 col-6">Ano Fab: {{ dados.ORDS_VEIC_ANO }}</a>
          <a class="col-lg-4 col-6">Chassi: {{ dados.ORDS_VEIC_CHASSI }}</a>
          <a class="col-lg-4 col-6">Modelo: {{ dados.ORDS_MDLV_DESCRICAO }}</a>
          <a class="col-lg-4 col-6"
            >KM/Cor: {{ dados.ORDS_VEIC_HODOMETRO }} -
            {{ dados.ORDS_VEIC_COR }}</a
          >
          <a class="col-lg-4 col-6">Frota: {{ dados.ORDS_VEIC_FROTA }} </a>
          <a class="col-lg-4 col-6">Placa: {{ dados.ORDS_VEIC_PLACA1 }}</a>
          <a class="col-lg-4 col-6">Motor: {{ dados.ORDS_VEIC_MOTOR }}</a>
          <a class="col-lg-4 col-6">Motorista: {{ dados.ORDS_MOTORISTA }}</a>
        </div>
        <div class="row  sub-header-order">
          <div class="col-lg-8">Serviços a serem executados</div>
        </div>
        <div class="order-item  row text-sub">
          <a class="col-lg-12">{{ dados.ORDS_SERVICOS }}</a>
        </div>
        <div class="row  sub-header-order">
          <div class="col-lg-8">Dados da Ordem de Serviço</div>
        </div>
        <div class="order-item row text-sub">
          <a class="col-lg-3 col-6">Emissão: {{ dados.ORDS_DTA_EMIS }}</a>
          <a class="col-lg-3 col-6">Técnico: {{ dados.ORDS_USR_ID }}</a>
          <a class="col-lg-3 col-6">Repres: </a>
          <a class="col-lg-3 col-6">Saída: {{ dados.ORDS_DTA_SAIDA_EDITED }}</a>
        </div>
        <div class="row  pr-2 mr-n2 sub-header-order">
          <div class="col-lg-9 col-6">Serviços</div>
          <div class="col-lg-1 col-2 text-right">QTD</div>
          <div class="col-lg-1 col-2 text-right">Vlr Unit</div>
          <div class="col-lg-1 col-2 text-right">Valor</div>
        </div>
        <div
          class="order-item row text-sub"
          v-for="(servico, i) in dados.ORDEM_SERVICO_I"
          :key="i"
        >
          <a class="col-lg-9 col-6"
            >{{ servico.ORDI_SERV_ID }} - {{ servico.ORDI_DESCRICAO }}
          </a>
          <a class="col-lg-1 col-2 text-right">{{ servico.ORDI_QTD }}</a>
          <a class="col-lg-1 col-2 text-right">{{
            servico.ORDI_VLR_UNIT_FORMATED
          }}</a>
          <a class="col-lg-1 col-2 text-right">{{
            servico.ORDI_VLR_OS_FORMATED
          }}</a>
        </div>
        <div class="row sub-header-order">
          <div :class="isMobile == false ? 'col-lg-7 col-4' : 'col-lg-7 col-6'">Peças</div>
          <div v-if="isMobile == false" class="col-lg-1 col-1 text-right">UN</div>
          <div v-if="isMobile == false" class="col-lg-1 col-1 text-right">ST</div>
          <div class="col-lg-1 col-2  text-right">QTD</div>
          <div class="col-lg-1 col-2 text-right">Vlr Unit</div>
          <div class="col-lg-1 col-2 text-right">Vlr Peças</div>
        </div>
        <div
          class="order-item  row text-sub"
          v-for="(pecas, p) in dados.ORDEM_SERVICO_P"
          :key="p"
        >
          <div :class="isMobile == false ? 'col-lg-7 col-4' : 'col-lg-7 col-6'">
         <a>  {{ pecas.ORDP_PROD_ID }} - {{ pecas.ORDP_DESCRICAO }}</a> 
          </div>
          <div v-if="isMobile == false" class="col-lg-1 col-1 text-right"><a>UN</a></div>
          <div v-if="isMobile == false" class="col-lg-1 col-1 text-right"><a>ST</a></div>
          <div class="col-lg-1 col-2  text-right"><a>{{ pecas.ORDP_QTD }}</a></div>
          <div class="col-lg-1 col-2  text-right">
          <a>{{ pecas.ORDP_VLR_UNIT_FORMATED }}</a> 
          </div>
          <div class="col-lg-1 col-2  text-right">
           <a> {{ pecas.ORDP_VLR_TOT_FORMATED }} </a>
          </div>
        </div>
        <div class="row  sub-header-order">
          <div class="col-lg-12">Observações: </div>
        </div>
        <div class="order-item  row text-sub">
          <a class="col-lg-12"> {{ dados.ORDS_OBS }}</a>
        </div>
        <div class="row  sub-header-order">
          <div class="col-lg-12">Total: </div>
        </div>
        <div class="order-item  row text-sub">
          <a class="col-lg-9 col-6"> </a>
          <a class="col-lg-2 col-4 text-left">Valor Serviço:</a>
          <a class="col-lg-1 col-2 text-right"
            >{{ dados.ORDS_VLR_OS_FORMATED }}
          </a>
          <a class="col-lg-9 col-6"> </a>
          <a class="col-lg-2 col-4 text-left">Valor em Peças:</a>
          <a class="col-lg-1 col-2 text-right">
            {{ dados.ORDS_VLR_PECAS_FORMATED }}</a
          >
          <a class="col-lg-9 col-6"> </a>
          <a class="col-lg-2 col-4 text-left">Total:</a>
          <a class="col-lg-1 col-2 text-right">
            {{ dados.ORDS_VLR_TOT_FORMATED }}</a
          >
        </div>
      </section>
    </div>
  </section>

  <!-- veiculo -->
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  <div >
    <section v-if="type == 'veiculo'" class="consulta-view">
      <div>
        <ul class="breadcrumb">
          <li>Placa: {{ dados[0].ORDS_VEIC_PLACA1 }}</li>

          <template v-if="dados">
            <li v-if="dados[0].ORDS_VEIC_CHASSI">
              Chassi: {{ dados[0].ORDS_VEIC_CHASSI }}
            </li>
            <li v-if="dados[0].ORDS_MRCV_DESCRICAO">
              Marca: {{ dados[0].ORDS_MRCV_DESCRICAO }}
            </li>
            <li v-if="dados[0].ORDS_MDLV_DESCRICAO">
              Modelo: {{ dados[0].ORDS_MDLV_DESCRICAO }}
            </li>
            <li v-if="dados[0].ORDS_VEIC_ANO">
              Ano: {{ dados[0].ORDS_VEIC_ANO }}
            </li>
            <li v-if="dados[0].ORDS_VEIC_COR">
              Cor: {{ dados[0].ORDS_VEIC_COR }}
            </li>
          </template>
        </ul>
      </div>
    </section>
    <section class="minH" v-if="type == 'veiculo'">
      <div v-for="(dado, i) in dados" :key="i" class="border-title">
        <div class="ml-4" @click="openModal[i] = !openModal[i]">
          OS: {{ dado.ORDS_ID }} - Data: {{ dado.ORDS_DTA_EMIS }}
          <i
            :class="
              openModal[i] == true
                ? 'fa fa-caret-up align-right'
                : 'fa fa-caret-down'
            "
          ></i>
        </div>
        <div class="border" v-if="openModal[i] == true">
          <section v-if="dado.ORDEM_SERVICO_I || dado.ORDEM_SERVICO_P">
            <div>
              <table
                v-if="
                  dado.ORDEM_SERVICO_I.length > 0 ||
                  dado.ORDEM_SERVICO_P.length > 0
                "
              >
                <tr>
                  <th>QTD</th>
                  <th>Item</th>
                </tr>

                <tr v-for="(servI, a) in dado.ORDEM_SERVICO_I" :key="a">
                  <td>{{ servI.ORDI_QTD }}</td>
                  <td>{{ servI.ORDI_DESCRICAO }}</td>
                </tr>
                <tr v-for="(servP, a) in dado.ORDEM_SERVICO_P" :key="a">
                  <td>{{ servP.ORDP_QTD }}</td>
                  <td>{{ servP.ORDP_DESCRICAO }}</td>
                </tr>
              </table>
            </div>
          </section>
          <section
            class="text-align-center"
            v-if="
              dado.ORDEM_SERVICO_I.length < 1 && dado.ORDEM_SERVICO_P.length < 1
            "
          >
            <a> Nenhum item cadastrado nesse atendimento... </a>
          </section>
        </div>
      </div>
    </section>

    <section v-if="type == 'equipamento'" class="spacer">
      <div>
        <ul class="breadcrumb mb-1">
          <li>Serie:  {{ dados[0].ORDS_EQPT_SERIE }}</li>
          <template v-if="dados[0]">
            <li>
             Modelo: {{ dados[0].ORDS_MDEQ_DESCRICAO }}
            </li>
            <li>
             Marca: {{ dados[0].ORDS_MREQ_DESCRICAO }}
            </li>
          </template>
        </ul>
      </div>
    </section>
    <section v-if="type == 'equipamento'" class="minH">
      <div class="border pt-2" v-for="(dado, i) in dados" :key="i">
        <div class="ml-4 mb-2 mt-2" @click="openModal[i] = !openModal[i]">
          OS: {{ dado.ORDS_ID }} - Data: {{ dado.ORDS_DTA_EMIS }}
          <i
            :class="
              openModal[i] == true ? 'fa fa-caret-up' : 'fa fa-caret-down'
            "
          ></i>
        </div>

        <div class="border pt-1 pb-1" v-if="openModal[i] == true">
          <section v-if="dado.ORDEM_SERVICO_I || dado.ORDEM_SERVICO_P">
            <div>
              <table>
                <tr>
                  <th width="50">QTD</th>
                  <th>Item</th>
                </tr>

                <tr v-for="(servI, a) in dado.ORDEM_SERVICO_I" :key="a">
                  <td>{{ servI.ORDI_QTD }}</td>
                  <td>{{ servI.ORDI_DESCRICAO }}</td>
                </tr>
                <tr v-for="(servP, a) in dado.ORDEM_SERVICO_P" :key="a">
                  <td>{{ servP.ORDP_QTD }}</td>
                  <td>{{ servP.ORDP_DESCRICAO }}</td>
                </tr>
              </table>
            </div>
          </section>
          <section
            v-if="
              dado.ORDEM_SERVICO_I.length < 1 && dado.ORDEM_SERVICO_P.length < 1
            "
          >
            Nenhum Item cadastrado nesse atendimento...
          </section>
        </div>
      </div>
    </section>

    <!-- <section v-if="!dados">
      <div class="no-item">Nenhum item encontrado!</div>
    </section> -->
  </div>
</template>
<script>
import api from "../config/api";
export default {
  name: "OrdemView",
  data: () => ({
    openModal: [],
    type: null,
    dados: "",
    isMobile: false,
  }),
  async created() {
    await this.verifyMobile();
   await this.getDadosOrdem();
  },

  methods: {
    verifyMobile() {
      screen.width <= 760 ? (this.isMobile = true) : (this.isMobile = false);
    },

    async getDadosOrdem() {
      let data = { hash: this.$route.path };

      try {
        let response = await api.get(`/consulta${data.hash}`);
        this.type = "ordem";
        this.dados = response.data[0];
        
        if(this.dados.ORDS_DTA_SAIDA) {
        this.dados.ORDS_DTA_SAIDA_EDITED = this.dados.ORDS_DTA_SAIDA.split("T");
       this.dados.ORDS_DTA_SAIDA_EDITED = this.dados.ORDS_DTA_SAIDA_EDITED[0].split('-').reverse().join('/')
        }
      } catch (error) {
        await this.getDadosVeiculos();
      }
    },

    async getDadosVeiculos() {
      let data = { hash: this.$route.path };

      try {
        let response = await api.get(`/veiculo/consulta${data.hash}`);
        this.type = "veiculo";
        this.dados = response.data;
      } catch (error) {
        await this.getDadosEquipamentos();
      }
    },
    async getDadosEquipamentos() {
      let data = { hash: this.$route.path };

      try {
        let response = await api.get(`/equipamento/consulta${data.hash}`);
        this.type = "equipamento";
        this.dados = response.data;
      } catch (error) {
        console.log("erro");
        this.type = 'not-items'
      }
    },
  },
};
</script>
<style>

.min{
  min-height: 1000px;;
}
a {
  font-size: 11px;
}
ul.breadcrumb {
  list-style: none;
  background-color: #eee;
  margin-top: -45px !important;
  padding-left: 15px;
  margin-bottom: -15px;
}

/* Display list items side by side */
ul.breadcrumb li {
  font-weight: 500;
  display: inline;
  font-size: 14px;
  text-transform: none !important;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 0px;
  color: black;
  content: "/\00a0";
}
.consulta-view {
  min-height: 580px;
}


.margin {
  min-height: 480px;
  padding-top: 70px;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.header-order {
  border: 1px;
  border-style: solid;
  min-height: 100px;
}
.sub-header-order {
  border: 1px;
  font-weight: 500;
  font-size: 13px;
  border-style: solid;

  background: #e9e9e9;
}
.text-sub {
  font-size: 13px;
}
.outer-text {
  font-size: 10px;
}
.order-item {
  min-height: 17px;
}


.button-consulta {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
.spacer {
  padding-top: 40px;
}
.minH{
  min-height: 550px;
}
.border{
  box-shadow: 1px 1px 2px 1px black;

}
.border-title {
  min-height: 50px;
  padding-top: 10px;

  margin-top: 5px;
  box-shadow: 1px 1px 2px 0.5px black;
  widows: 100%;
}
.no-item {
  padding-top: 20px;
  text-align: center;
}

/* Style the list */
ul.breadcrumb {
  list-style: none;
  background-color: #eee;
  margin-top: -35px;
  padding-left: 15px;
}

/* Display list items side by side */
ul.breadcrumb li {
  font-weight: 500;
  display: inline;
  font-size: 14px;
  text-transform: none !important;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 2px;
  color: black;
  content: "/\00a0";
}
.consulta-view {
  min-height: 580px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;

}

td {
    border: 1px solid #dddddd;
  text-align: left;
   padding-left: 10px;
}
th {
  border: 1px solid #dddddd;
  text-align: left;
   padding-left: 5px;



}

tr:nth-child(even) {
  background-color: #dddddd;
  
}
/* Style buttons */
.btn {
background-color: white;
border: none;
  color: black; 
padding: 5px; 
margin-bottom: 10px;/* Some padding */
  font-size: 20px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}
.text-align-center{
  text-align: center;

}
.align-right{
  text-align: right;
}

</style>

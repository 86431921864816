<template>
<div class="notify">

</div>

</template>

<script>


export default {
  name: 'NotifyComponent',

}
</script>

<style>
.notify{
padding: 15px;
 position: fixed;
 width: 100%;
top: 10px;
text-align: right;
}

</style>

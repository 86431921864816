<template>
<div class="card text-center fo-size">

  <div class="card-footer text-muted">
    ©  Copyright BSSOFT. Todos os direitos reservados -
Desenvolvido por <a class="fo-size" href="https://pt-br.facebook.com/bssoftoficial/" target="_blank">BSSoft</a>
  </div>
</div>

</template>

<script>


export default {
  name: 'FooterComponent',

}
</script>

<style>
.footer{
 
padding: 10px;
bottom: 0px;
 width: 100%;

text-align: center;
}
.fo-size{
  font-size: 12px;
}
</style>

<template>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
 
  <v-app>
<notify-component/>
    <nav-bar v-if="!$route.meta.hideNavbar" />
    <router-view/>
     <footer-component/> 
  </v-app>
</template>

<script>
import FooterComponent from './components/layouts/FooterComponent.vue';
import NavBar from "./components/layouts/NavBar.vue";
import NotifyComponent from './components/NotifyComponent.vue'

export default {
  name: "App",
  components: { NavBar, FooterComponent, NotifyComponent },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  list-style: none;
  text-decoration: none;
}
:root {
  --red: #d63a25;
  --white: #fff;
  --dark: #1e1c2a;
}
body {
  color: var(--dark);
  background: var(--white);
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 40px;
  box-shadow: 0 0.1rem 0.5rem #ccc;
  width: 100%;
  background: var(--white);
  transition: all 0.5s;
  position: fixed;
}
.navigation .logo {
  color: var(--red);
  font-size: 1.7rem;
  font-weight: 600;
}
.logo span {
  color: var(--dark);
}
.navigation ul {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.navigation ul li a {
  color: var(--dark);
  font-size: 17px;
  font-weight: 500;
  transition: all 0.5s;
}
.navigation ul li a:hover {
  color: var(--red);
}
.navigation i {
  cursor: pointer;
  font-size: 1.5rem;
}
.menu {
  cursor: pointer;
  display: none;
}
.menu .bar {
  display: block;
  width: 28px;
  height: 3px;
  border-radius: 3px;
  background: var(--dark);
  margin: 5px auto;
  transition: all 0.3s;
}
.menu .bar:nth-child(1),
.menu .bar:nth-child(3) {
  background: var(--red);
}
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10%;
}

.home-text {
  max-width: 37rem;
}
.home-text .text-h4 {
  font-size: 1.5rem;
  color: var(--red);
  margin-bottom: 1rem;
  margin-top: 30px;
}
.home-text .text-h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  line-height: 4rem;
}
.text-h1-white {
  font-size: 4rem;
  margin-bottom: 1rem;
  line-height: 4rem;
  color: white;
}
.text-white{
      color: white;
}
.home-text p {
  margin-bottom: 4rem;
}
.home-equipamento {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10%;
  background-color: #01141F;
}
.home-equipamento-text p {
  margin-bottom: 4rem;
  color:white
}
.home-equipamento-text .text-h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  line-height: 4rem;
  color: white;
}
.home-input {
  padding: 14px 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  color: var(--black);
  border-radius: 10px;
  font-size: 15px;
  font-weight: 750;
  transition: all 0.5s;
}
.home-btn {
  padding: 15px 25px;
  background: var(--red);
  color: var(--white);
  border-radius: 10px;
  font-weight: 700;
  transition: all 0.5s;
  cursor: pointer;
}
.home-btn-black {
  padding: 15px 25px;
  background: black;
  color: var(--white);
  border-radius: 10px;
  font-weight: 700;
  transition: all 0.5s;
  cursor: pointer;
}
.home-btn2 {
  padding: 15px 25px;
  background: #574A94;
  color: var(--white);
  border-radius: 10px;
  font-weight: 700;
  transition: all 0.5s;
  cursor: pointer;
}
.aqui-btn {
  padding: 1px 2px;
  background: black;
  color: var(--white);
  border-radius: 5px;
  font-weight: 20;
  transition: all 0.5s;
  cursor: pointer;
}
.home-btn:hover {
  background: #fc4c35;
}
.home-img img {
  width: 100%;
  text-align: right;
}
@media (max-width: 785px) {
  .navigation {
    padding: 18px 20px;
  }
  .menu {
    display: block;
  }
  .menu.ativo .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .menu.ativo .bar:nth-child(2) {
    opacity: 0;
  }
  .menu.ativo .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .nav-menu {
    position: fixed;
    right: -100%;
    top: 70px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: var(--white);
    gap: -10px;
    transition: 0.3s;
  }
  .nav-menu.ativo {
    right: 0;
  }
  .nav-item {
    margin: 16px 0;
  }
  /*main*/
  .home {
    padding: 100px 2%;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    gap: 5rem;
  }
  .home .text-h4 {
    font-size: 15px;
  }
  .home .text-h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    
  }
  .home p {
    font-size: 15px;
  }
  .home-img {
    width: 125%;
  }

   .home-equipamento {
    padding: 100px 2%;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    gap: 5rem;
  }
  .home-equipamento .text-h4 {
    font-size: 15px;
  }
  .home-equipamento .text-h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    color: white;
  }
  .home-equipamento p {
    font-size: 15px;
     color: white;
  }

}
</style>
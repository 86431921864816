import { createRouter, createWebHistory } from 'vue-router'

import OrdemView from '../views/OrdemView.vue'
const routes = [


  {
    path: '/:hash/',
    name: 'ordem',
    component: OrdemView,
    meta: {
      hideNavbar: true,
     }
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

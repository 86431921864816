<template>
 <nav class="navigation">
  
  <img src="http://www.bssoft.com.br/assets/img/logo.png" @click="   $router.push({
            name: 'home',
          })" class="img-logo">
            <!-- <a class="logo">F<span>oo</span>D P<span>ee</span>K</a> -->

         
       
        </nav>
 
</template>

<script>


export default {
  name: 'NavBar',

}
</script>

<style>
.header{
   text-align: left;
}
.img-logo{
  max-height: 30px;
}
.text-center{
  text-align: center !;
}
</style>
